import React from 'react';
import styles from './style.module.scss';
import { Divider, Spin, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import { CvMiniEng, DownloadSvg, EditSvg, CvMiniRu } from '@app/assets/svg';
import { useNavigate } from 'react-router-dom';
import { CvApi, CvModel } from '@entities/cv';
import { getDate } from '@shared/lib/getDateString';
import { useAppStore } from '@processes/app/store';
import { useMutation } from 'react-query';

type TCard = {
	lang: 'ru' | 'en';
	cv: CvModel.TCv;
};

const { Text } = Typography;

const CvCardMini: React.FC<TCard> = ({ lang, cv }) => {
	const SetCvLanguage = useAppStore((state) => state.SetCvLanguage);
	const isEnLang = lang === 'en';
	const isRuLang = lang === 'ru';

	const navigate = useNavigate();
	const { mutate: cvDownload, isLoading: isLoadingDownload } = useMutation(CvApi.generate);

	function onCvDownload() {
		const fileName = isEnLang
			? cv.filename + ' [' + getDate(cv.dateUpdated) + ']_en'
			: cv.filename + ' [' + getDate(cv.dateUpdatedRu) + ']_ru';
		cvDownload({ filename: fileName, userId: cv.userDataId, language: lang });
	}

	function onOpenCv(event: React.MouseEvent<Element, MouseEvent>) {
		SetCvLanguage(lang);
		if (event.ctrlKey) {
			window.open(`/cv/${cv.userDataId}?username=${cv.username}`, '_blank', 'noopener,noreferrer');
		} else {
			navigate(`/cv/${cv.userDataId}?username=${cv.username}`);
		}
	}

	const isDisabled = isRuLang ? !cv.dateUpdatedRu : !cv?.userDataId;
	const date = getDate(isEnLang ? cv.dateUpdated : cv.dateUpdatedRu);
	const isActionsShow = (isEnLang && cv?.userDataId) || (isRuLang && cv?.cvRuIsGenerated);

	return (
		<Spin spinning={isLoadingDownload}>
			<div className={classNames(styles.card, { disabled: isDisabled })}>
				<div className={classNames(styles.card__image)}>
					{isEnLang ? <CvMiniEng /> : <CvMiniRu />}
				</div>
				<div className={styles.card__actions}>
					{isActionsShow && (
						<div className={styles.card__content}>
							<Tooltip title={'Download'} placement={'top'}>
								<DownloadSvg className={styles.icon} onClick={onCvDownload} />
							</Tooltip>
							<Divider type="vertical" />
							<Tooltip title={'Edit Cv'} placement={'top'}>
								<EditSvg className={styles.icon} onClick={onOpenCv} />
							</Tooltip>
						</div>
					)}
				</div>
			</div>
			<Text className={'card__date'}>{date}</Text>
		</Spin>
	);
};

export default CvCardMini;

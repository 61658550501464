import React from 'react';
import styles from './style.module.scss';
import { Button, Dropdown, Menu, Spin, Typography } from 'antd';
import classNames from 'classnames';
import { MoreSvg } from '@app/assets/svg';
import { useNavigate } from 'react-router-dom';
import { CvApi, CvModel } from '@entities/cv';
import { getDate } from '@shared/lib/getDateString';
import { useAppStore } from '@processes/app/store';
import { useMutation } from 'react-query';

type TCard = {
	cv: CvModel.TCv;
};

const CvCardCustom: React.FC<TCard> = ({ cv }) => {
	const { mutate: cvDownload, isLoading: isLoadingDownload } = useMutation(CvApi.generate);

	function onCvDownload(customType: string) {
		const fileName = cv.filename + ' [' + getDate(cv.dateUpdatedRu) + ']_' + customType;
		cvDownload({ filename: fileName, userId: cv.userDataId, language: customType });
	}

	function handleMenuClick(e: { key: string }) {
		onCvDownload(e.key);
	}

	const items = [
		{ label: 'Lanit', key: 'lanit' },
		{ label: 'MosBir', key: 'mosb' },
		{ label: 'Alfa', key: 'alfa' },
		{ label: 'VK', key: 'vk' },
		{ label: 'Croc', key: 'croc' },
		{ label: 'jet', key: 'jet' },
		{ label: 'vtb', key: 'vtb' },
	];

	const menuProps = {
		items,
		onClick: handleMenuClick,
	};

	return (
		<Spin spinning={isLoadingDownload}>
			<div className={classNames(styles.card, { disabled: false })}>
				<div className={classNames(styles.card__image)}>
					<Dropdown menu={menuProps}>
						<Button className={classNames(styles.card__button)}>
							<MoreSvg />
						</Button>
					</Dropdown>
				</div>
			</div>
		</Spin>
	);
};

export default CvCardCustom;

import React from 'react';
import styles from './style.module.scss';
import { Typography } from 'antd';
import CvCardMini from '../cv-card-mini';
import CvCardCustom from '../cv-card-custom';
import { UserLib } from '@entities/user';
import { CvModel } from '@entities/cv';

const { Text } = Typography;

type TListItem = CvModel.TCv;

const CvListItem: React.FC<TListItem> = (cv) => {
	return (
		<div className={styles.item}>
			<div className={styles.inner}>
				{cv.companyId && <span className={styles.inner__id}>{`${cv.companyId}`}</span>}
				<div className={styles.inner__item}>
					<div className={styles.avatar}>{UserLib.getFirstLetters(cv.username)}</div>
					<div className={styles.info}>
						<Text className={styles.info__name}>{`${cv.lastName} ${cv.firstName}`}</Text>
						<Text className={styles.info__file}>{`${cv.filename}.docx`}</Text>
					</div>
				</div>
				<div className={styles.inner__item}>
					<Text className={styles.updated}>Updated:</Text>
					<div className={styles.lang}>
						<CvCardMini lang={'en'} cv={cv} />
						<CvCardMini lang={'ru'} cv={cv} />
						<CvCardCustom cv={cv} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default CvListItem;

import React, { MouseEvent, useMemo, useState } from 'react';
import styles from './style.module.scss';
import { CvCard, CvListItem } from '@entities/cv';
import { Button, Input, List, Select, SelectProps, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useAppSelector } from '@shared/model';
import { CvModel } from '@entities/cv';
import { SortAlphabetSvg, SortNotAlphabetSvg } from '@app/assets/svg';
import { usePagination } from '@pages/cv/teamlead/lib';
import { CvUserModel } from '@features/cv-user';
import { useLocale } from '@processes/app/lib';
import { DownOutlined } from '@ant-design/icons';

const { Text } = Typography;

const text = {
	en: {
		title: "Employee Profile's",
		placeholder: 'Search by id, first and last name',
		select: 'Specializations',
	},
	ru: {
		title: 'Резюме сотрудников',
		placeholder: 'Поиск по id, имени и фамилии',
		select: 'Специализация',
	},
};

const specsArray = [
	'Android',
	'Angular',
	'BigData',
	'C#/.Net',
	'CAD',
	'DevOps',
	'Flutter',
	'Golang',
	'Graphic Design',
	'HTML',
	'Hybris',
	'IOS',
	'ITA',
	'Java',
	'NodeJs',
	'PHP',
	'PM',
	'Python',
	'QA Automation',
	'QA',
	'React',
	'SFDC',
	'UX/UI',
	'Vue',
	'С++',
];

const TeamLeadPage = () => {
	const [sort, setSort] = useState<'ASC' | 'DEC'>('ASC');
	const searchParams = localStorage.getItem('search');
	const [search, setSearch] = useState(searchParams ? JSON.parse(searchParams + '').input : '');
	const [specs, setSpecs] = useState(searchParams ? JSON.parse(searchParams + '').specs : []);
	const isAdmin = useAppSelector(CvModel.selectors.isAdmin);
	const { handleChange, size, current, pageSizeOptions } = usePagination();
	const cv = CvUserModel.useGetCvUser();
	const { locale } = useLocale();

	const cvList = CvUserModel.useGetCvWithoutUser();
	const { isLoading } = useAppSelector(CvModel.selectors.cvStatus);

	const list = useMemo(() => {
		if (!cvList) return [];
		let list = [...cvList];

		if (specs && specs.length > 0) {
			const q: CvModel.TCv[] = [];
			specs.forEach((filterValue: string) => {
				q.push(
					...cvList.filter((cv) => cv.specializations && cv.specializations.includes(filterValue)),
				);
			});
			list = q;
		}

		if (search) {
			list = list.filter(
				(cv) =>
					cv.username.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
					(cv.companyId && cv.companyId.toString() === search),
			);
		}

		if (sort === 'DEC') {
			list.reverse();
		}

		localStorage.setItem('search', JSON.stringify({ input: search, specs: specs }));

		return list;
	}, [cvList, search, sort, specs]);

	function onSort(_event: MouseEvent<HTMLElement>) {
		setSort((prevState) => (prevState === 'ASC' ? 'DEC' : 'ASC'));
	}

	const options: SelectProps['options'] = [];
	specsArray.forEach((item) => {
		options.push({
			label: item,
			value: item,
		});
	});

	const suffix = (
		<>
			<span>{specs.length}</span>
			<DownOutlined />
		</>
	);

	return (
		<div className={styles.teamlead}>
			<div className={styles.cards}>
				{!cv && <Text className={styles.title}>You haven't created any of your CVs</Text>}
				<CvCard lang="en" cv={cv} />
				<CvCard lang="ru" cv={cv} />
			</div>
			<div className={styles.list}>
				<div className={styles.filters}>
					<Button className={styles.sort} onClick={onSort}>
						{sort === 'ASC' ? <SortAlphabetSvg /> : <SortNotAlphabetSvg />}
					</Button>
					{isAdmin && (
						<div className={styles.search}>
							<Select
								mode="multiple"
								value={specs}
								placeholder={text[locale].select}
								style={{ width: '100%' }}
								onChange={setSpecs}
								options={options}
								suffixIcon={suffix}
								variant="outlined"
								maxTagCount={'responsive'}
							/>
						</div>
					)}
					<div className={styles.search}>
						<Input
							name={'search'}
							onChange={(event) => setSearch(event.target.value)}
							placeholder={text[locale].placeholder}
							prefix={<SearchOutlined />}
							value={search}
							allowClear
						/>
					</div>
				</div>
				<Text className={styles.list_title}>{`${text[locale].title} (${list.length})`}</Text>
				<div className={styles.list_items}>
					<List
						dataSource={list}
						loading={isLoading}
						pagination={
							list?.length > 10 && {
								pageSizeOptions,
								position: 'bottom',
								current,
								pageSize: size,
								onChange: handleChange,
								className: styles.pagination,
								showSizeChanger: true,
								total: list?.length,
								showTotal: (total, _range) =>
									locale === 'en'
										? `Showing ${_range[0]} to ${_range[1]} of ${total} employees`
										: `Отображено от ${_range[0]} до ${_range[1]} из ${total} сотрудников`,
							}
						}
						renderItem={(cv) => <CvListItem key={cv.userId} {...cv} />}
					/>
				</div>
			</div>
		</div>
	);
};

export default TeamLeadPage;

import React, { FC } from 'react';
import styles from './style.module.scss';
import { Dropdown, Menu, Modal } from 'antd';
import { DeleteSvg, EditSvg, MoreSvg } from '@app/assets/svg';
import { useAppStore } from '@processes/app/store';
import { ExclamationCircleFilled } from '@ant-design/icons';

type IEditItemProps = {
	onEdit?: () => void;
	onDelete: () => void;
};

const EditItem: FC<IEditItemProps> = ({ onEdit, onDelete }) => {
	const locale = useAppStore((state) => state.locale);
	const { confirm } = Modal;

	const menuItemsDelete = [
		{
			key: 'Delete',
			icon: <DeleteSvg />,
			label: locale === 'en' ? 'Delete' : 'Удалить',
		},
	];

	const menuItemsEdit = [
		{
			key: 'Edit',
			icon: <EditSvg />,
			label: locale === 'en' ? 'Edit' : 'Редактировать',
		},
		{
			key: 'Delete',
			icon: <DeleteSvg />,
			label: locale === 'en' ? 'Delete' : 'Удалить',
		},
	];
	function handleClick(e: any) {
		if (e.key === 'Delete') {
			confirm({
				title:
					locale === 'en' ? 'Do you want to delete this item?' : 'Вы действительно хотите удалить?',
				icon: <ExclamationCircleFilled />,
				// content: 'Some descriptions',
				okText: locale === 'en' ? 'Yes' : 'Да',
				cancelText: locale === 'en' ? 'No' : 'Нет',
				onOk() {
					onDelete();
				},
				onCancel() {},
			});
		} else {
			!!onEdit && onEdit();
		}
	}

	const menu = {
		items: onEdit ? menuItemsEdit : menuItemsDelete,
		onClick: handleClick,
	};

	return (
		<div className={styles.edit}>
			<Dropdown
				placement={'bottomRight'}
				overlayClassName={'edit_dropdown'}
				menu={menu}
				trigger={['click']}
			>
				<div>
					<MoreSvg />
				</div>
			</Dropdown>
		</div>
	);
};

export default EditItem;

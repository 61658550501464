import { useCallback, useState } from 'react';
const pageSizeOptions = [10, 20, 30, 50];
export function usePagination() {
	const pagParams = localStorage.getItem('pagination');
	const [current, setCurrent] = useState(pagParams ? JSON.parse(pagParams + '').page : 1);
	const [size, setSize] = useState(pagParams ? JSON.parse(pagParams + '').pageSize : 10);

	const handleChange = useCallback((page: number, pageSize: number) => {
		localStorage.setItem('pagination', JSON.stringify({ page: page, pageSize: pageSize }));
		setCurrent(page);
		setSize(pageSize);
	}, []);

	return { size, handleChange, current, pageSizeOptions };
}
